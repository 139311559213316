<template>
	<v-card class="mx-auto" :loading="loadingParent" rounded="xl">
		<v-card-title>
			<span><v-icon>mdi-account-group</v-icon> {{ $t('attendances.attendances') }}</span>
			<span style="color: #FF6F00" class="ml-2">{{ usersTotalText }}</span>
			<v-spacer />
			<div class="mr-3" style="max-width: 150px">
				<v-select
					v-model="showOption"
					:label="$t('search.show')"
					single-line
					:items="showOptions"
					item-value="key"
					item-text="name"
					return-object
					hide-details
				/>
			</div>
			<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search.search')" single-line hide-details />
		</v-card-title>

		<v-data-table
			:headers="headers"
			:items="filteredAttendances"
			:items-per-page="10"
			:loading="loadingAttendances"
			:locale="$i18n.locale"
			disable-sort
			class="elevation-1"
			v-if="attendances.length && event.attendances"
		>
			<template #item="{item, isMobile}">
				<template v-if="isMobile">
					<EventAttendancesUserItemMobile :loading-parent="loadingAttendances" :item="item" />
				</template>
				<template v-else>
					<EventAttendancesUserItemDesktop :loading-parent="loadingAttendances" :item="item" />
				</template>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'EventAttendances',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loadingAttendances: false,
			search: '',
			showOption: { key: 'all', name: this.$t('applications.all') },
			showOptions: [
				{ key: 'all', name: this.$t('applications.all') },
				{ key: 'normal', name: this.$t('applications.normal') },
				{ key: 'prioritized', name: this.$t('applications.prioritized') },
				{ key: 'discarded', name: this.$t('applications.discarded') }
			],
			headers: [
				{ text: this.$t('auth.name'), value: 'user', align: 'center' },
				{ text: this.$t('settings.documents'), value: 'documents', align: 'center' },
				{ text: this.$t('settings.action'), value: 'action', align: 'center' }
			]
		}
	},
	components: {
		EventAttendancesUserItemDesktop: () => import('@/components/events/EventAttendancesUserItemDesktop.vue'),
		EventAttendancesUserItemMobile: () => import('@/components/events/EventAttendancesUserItemMobile.vue')
	},
	computed: {
		filteredAttendances() {
			return this.attendances.filter(
				(attendance) =>
					removeAccents(attendance.user.fullname)
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase()) && this.statusShown(attendance)
			)
		},
		usersTotalText() {
			if (!this.event) return ''
			if (this.event.type.code == 'interview') {
				return `${this.event.attendances.numTotal > 0 ? this.event.attendances.numTotal : '--'} / ${this.event.spots.length * this.event.info.people}`
			} else if (this.event.type.code == 'workshop') {
				return `${this.event.attendances.numPrioritized > 0 ? this.event.attendances.numPrioritized : '--'} / ${this.event.attendances.maxCapacity}`
			} else {
				return `${this.event.attendances.numTotal > 0 ? this.event.attendances.numTotal : '--'} / ${this.event.attendances.maxCapacity}`
			}
		},
		...mapGetters({
			event: 'events/event',
			attendances: 'attendances/attendances'
		})
	},
	created() {
		this.loadingAttendances = true
		this.fetchAttendances(this.$route.params.id).then(() => {
			this.loadingAttendances = false
		})
	},
	methods: {
		statusShown(attendance) {
			if (this.showOption.key == 'all') {
				return true
			} else if (this.showOption.key == 'normal') {
				return attendance.status.normal
			} else if (this.showOption.key == 'prioritized') {
				return attendance.status.prioritized
			} else if (this.showOption.key == 'discarded') {
				return attendance.status.discarded
			}
		},
		...mapActions('attendances', ['fetchAttendances'])
	}
}
</script>
